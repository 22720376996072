<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex" style="gap: 20px">
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          height="40px"
          label="Search document"
          prepend-inner-icon="mdi-magnify"
          color="primary"
          class="rounded-lg"
        ></v-text-field>
      </div>
      <v-btn
        class="text-capitalize rounded-lg"
        color="primary"
        height="40px"
        outlined
        @click="
          toggleAddUpdateDocument({ show: true, id: null, type: $keys.ADD })
        "
      >
        <v-icon size="16" left>mdi-plus</v-icon>Add New Document
      </v-btn>
    </div>

    <v-data-table
      class="elevation-3"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:item.index="{ index }">
        {{ index + 1 }}
      </template>

      <template v-slot:item.description="{ item }">
        <div v-if="item.description">
          {{ item.description }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex justify-center">
          <v-switch
            color="success"
            v-model="item.is_active"
            @change="changeStatus(item.is_active, item.id)"
            :label="item.is_active ? 'Active' : 'In-active'"
          ></v-switch>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            class="text-capitalize rounded-lg"
            color="primary"
            height="32px"
            @click="
              toggleAddUpdateDocument({
                show: true,
                type: $keys.EDIT,
                id: item.id,
              })
            "
          >
            edit
          </v-btn>
          <v-btn
            small
            outlined
            class="text-capitalize rounded-lg"
            color="primary"
            height="32px"
            @click="openCnfDialog(item, $keys.DELETE)"
          >
            delete
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
            class="rounded-lg"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <AddUpdateDocumentDialog @submitted="getDocumentList" />
    <ConfirmationDialog
      @confirmed="deleteDocument"
      height="130px"
      width="130px"
    >
      <template v-slot:confirmBtnText> Yes Delete </template>
    </ConfirmationDialog>
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions } from "vuex";

import AddUpdateDocumentDialog from "@/views/app/admin/documentManagement/addUpdateDocument";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { ConfirmationDialog, AddUpdateDocumentDialog },
  data() {
    return {
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_total: 1,
      page_number: 1,
      page_count: 10,
      total_count: 0,

      loading: false,
      items: [],
      headers: [
        {
          sortable: false,
          text: "#",
          value: "index",
        },
        {
          sortable: false,
          text: "Name",
          value: "title",
        },
        {
          sortable: false,
          text: "Description",
          value: "description",
        },
        {
          sortable: false,
          text: "Status",
          value: "status",
        },
        {
          sortable: false,
          text: "Action",
          value: "action",
        },
      ],

      search: "",
      selectedItem: null,
    };
  },
  computed: {
    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },
  watch: {
    search() {
      this.debouncedReloadData();
    },

    page_count() {
      this.getDocumentList();
    },

    page_number() {
      this.getDocumentList();
      this.updateRoute();
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.search = query.search;
    }

    this.updateRoute();
  },

  mounted() {
    this.getDocumentList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      toggleCnfDialog: "dialogs/toggleConfirmationDialog",
      setCnfDialogBtnLoading: "dialogs/setConfirmationDialogBtnLoading",
      toggleAddUpdateDocument: "admin/toggleAddUpdateDocument",
      showSnackbar: "app/showSnackbar",
    }),

    navClickHandler(navKey) {
      if (navKey === "document_management") {
        this.getDocumentList();
        this.updateRoute();
      }
    },

    debouncedReloadData: _.debounce(function () {
      this.getDocumentList();
      this.updateRoute();
    }, 1000),

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    getDocumentList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        search: this.search,
      };

      return this.$request(this.$keys.GET, this.$urls.master.document.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    openCnfDialog(item, action) {
      this.toggleCnfDialog({ show: true, action: action });
      this.selectedItem = item.id;
    },

    changeStatus(value, id) {
      const data = {
        is_active: value,
      };

      const params = {
        document_id: id,
      };

      return this.$request(
        this.$keys.PATCH,
        this.$urls.master.document.update,
        {
          params,
          data,
        }
      );
    },

    deleteDocument() {
      const onSuccess = (res) => {
        this.toggleCnfDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.getDocumentList();
      };

      const onFinally = () => {
        this.setCnfDialogBtnLoading({ loading: false });
      };

      const params = {
        document_id: this.selectedItem,
      };

      return this.$request(
        this.$keys.DELETE,
        this.$urls.master.document.delete,
        {
          params,
          onSuccess,
          onFinally,
        }
      );
    },
  },
};
</script>
