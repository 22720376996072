<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="400px"
    content-class="rounded-xl"
  >
    <v-overlay class="justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">
          {{ type === $keys.EDIT ? "Edit" : "Add" }} Document
        </div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form class="pt-5" lazy-validation ref="form">
          <v-text-field
            v-model="form.title"
            label="Name"
            :rules="[$rules.required]"
            height="42px"
            type="text"
            outlined
            dense
            class="rounded-lg"
          ></v-text-field>

          <v-textarea
            rows="2"
            type="text"
            v-model="form.description"
            label="Description"
            flat
            outlined
            dense
            class="rounded-lg"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="submit"
          :loading="btnLoading"
          color="primary"
          class="text-capitalize px-8 my-2 rounded-lg"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      btnLoading: false,

      form: {
        title: "",
        description: "",
        is_active: true,
      },
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  computed: {
    ...mapGetters({
      addUpdateDocument: "admin/addUpdateDocument",
    }),

    show: {
      get() {
        return this.addUpdateDocument.show;
      },
      set(value) {
        this.toggleAddUpdateDocument({ show: value });
      },
    },

    type() {
      return this.addUpdateDocument.type;
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleAddUpdateDocument: "admin/toggleAddUpdateDocument",
    }),

    async openModal() {
      this.loading = true;

      if (this.type === this.$keys.EDIT) {
        await this.getDocumentDetail();
      }

      this.loading = false;
    },

    modalClosed() {
      this.toggleAddUpdateDocument({ show: false });
      this.form = {
        title: "",
        description: "",
        is_active: true,
      };
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const data = { ...this.form };

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        if (this.type === this.$keys.EDIT) {
          const params = {
            document_id: this.addUpdateDocument.id,
          };

          return this.$request(
            this.$keys.PATCH,
            this.$urls.master.document.update,
            {
              params,
              data,
              onSuccess,
              onFinally,
            }
          );
        } else {
          return this.$request(
            this.$keys.POST,
            this.$urls.master.document.create,
            {
              data,
              onSuccess,
              onFinally,
            }
          );
        }
      } else {
        this.$nextTick(() => {
          const el = document.getElementsByClassName("v-input error--text");
          if (el)
            el[0].scrollIntoView({
              block: "end",
              behavior: "smooth",
            });
          return;
        });
      }
    },

    getDocumentDetail() {
      const onSuccess = (res) => {
        this.form.title = res.data.data.title;
        this.form.description = res.data.data.description;
        this.form.is_active = res.data.data.is_active;
      };

      const params = {
        document_id: this.addUpdateDocument.id,
      };

      return this.$request(this.$keys.GET, this.$urls.master.document.detail, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
