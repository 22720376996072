var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"20px"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"clearable":"","outlined":"","dense":"","height":"40px","label":"Search document","prepend-inner-icon":"mdi-magnify","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":function($event){return _vm.toggleAddUpdateDocument({ show: true, id: null, type: _vm.$keys.ADD })}}},[_c('v-icon',{attrs:{"size":"16","left":""}},[_vm._v("mdi-plus")]),_vm._v("Add New Document ")],1)],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"items-per-page":_vm.page_count,"server-items-length":_vm.page_count,"loading":_vm.loading,"loading-text":"Please wait.... Loading","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('div',[_vm._v(" "+_vm._s(item.description)+" ")]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"color":"success","label":item.is_active ? 'Active' : 'In-active'},on:{"change":function($event){return _vm.changeStatus(item.is_active, item.id)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.toggleAddUpdateDocument({
              show: true,
              type: _vm.$keys.EDIT,
              id: item.id,
            })}}},[_vm._v(" edit ")]),_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"small":"","outlined":"","color":"primary","height":"32px"},on:{"click":function($event){return _vm.openCnfDialog(item, _vm.$keys.DELETE)}}},[_vm._v(" delete ")])],1)]}}],null,true)}),_c('v-container',{staticClass:"d-flex justify-space-between align-center px-0 mb-0 mt-4",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center text-subtitle-2"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rows per page")]),_c('div',{staticStyle:{"width":"75px"}},[_c('v-select',{staticClass:"rounded-lg",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.itemsPerPageList,"hide-details":"","dense":"","solo":"","color":"primary"},model:{value:(_vm.page_count),callback:function ($$v) {_vm.page_count=$$v},expression:"page_count"}})],1),_c('div',{staticClass:"ml-6"},[_vm._v(" Results: "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageEnd)+" of "+_vm._s(_vm.total_count)+" ")])]),_c('v-pagination',{attrs:{"length":_vm.page_total,"total-visible":5,"color":"primary"},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1),_c('AddUpdateDocumentDialog',{on:{"submitted":_vm.getDocumentList}}),_c('ConfirmationDialog',{attrs:{"height":"130px","width":"130px"},on:{"confirmed":_vm.deleteDocument},scopedSlots:_vm._u([{key:"confirmBtnText",fn:function(){return [_vm._v(" Yes Delete ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }